import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cn } from 'app/utils/tw';
import { RiCircleLine } from '@remixicon/react';
import { Label } from './label';

export interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  id: string;
  label?: React.ReactNode;
  disabled?: boolean;
  divClassName?: string;
  labelClassName?: string;
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    { className, divClassName, labelClassName, id, label, disabled, ...props },
    ref,
  ) => (
    <div
      className={cn('flex items-center', divClassName)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CheckboxPrimitive.Root
        id={id}
        ref={ref}
        disabled={disabled}
        className={cn(
          'h-5 w-5 shrink-0 rounded-sm border border-homy-gray-light ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-homy-gray-lighter disabled:text-homy-gray-lighter data-[state=checked]:border-homy-gray-light data-[state=checked]:bg-white data-[state=checked]:text-primary-foreground disabled:data-[state=checked]:bg-homy-gray-lighter',
          className,
        )}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn('flex items-center justify-center text-current')}
        >
          <RiCircleLine
            className={cn(
              'h-3 w-3 rounded-sm bg-homy-green fill-current text-homy-green ',
              {
                'bg-homy-gray-light text-homy-gray-light':
                  disabled && props.checked,
              },
            )}
          />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && (
        <Label
          className={cn('ml-2', labelClassName)}
          variant={'checkbox'}
          htmlFor={id}
          disabled={disabled}
        >
          {label}
        </Label>
      )}
    </div>
  ),
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
